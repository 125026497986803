.generic-error-inline-wrapper {
  width: 100%;
  max-width: 400px;
  text-align: center;
  margin: 24px auto;
  .generic-error-inline-title {
    margin-top: 24px;
  }
  .generic-error-inline-desc {
    margin-top: 12px;
  }
  .generic-error-inline-refresh {
    margin-top: 24px;
    padding: 5px 0;
  }
}
