@import "../../../styles/colors.scss";
@import "../../../styles/mixins.scss";

.favourites-wrapper-shimmer {
  min-height: calc(100vh - 430px);
  background-color: $white-color !important;
  @include wrapper-shimmer;
  margin-top: 32px;
  .favourites-container-shimmer {
    @include container-shimmer;
    .favourites-header-shimmer {
      @include header-shimmer;
      margin-bottom: 0;
      width: 150px;
    }
    .favourites-card-shimmer {
      display: flex;
      flex-direction: column;
      gap: 30px;
    }
  }
}

@media only screen and (min-width: 768px) {
  .favourites-wrapper-shimmer {
    margin-top: 0px;
    .favourites-container-shimmer {
      .favourites-card-shimmer {
        padding: 24px 0px;
        display: grid;
        grid-template-columns: calc(50% - 30px) calc(50% - 30px);
        grid-gap: 24px;
        box-sizing: border-box;
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .favourites-wrapper-shimmer {
    .favourites-container-shimmer {
      .favourites-card-shimmer {
        padding: 32px 0px;
        grid-template-columns: calc(33.33% - 30px) calc(33.33% - 30px) calc(
            33.33% - 30px
          );
        grid-gap: 32px;
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .favourites-wrapper-shimmer {
    .favourites-container-shimmer {
      .favourites-card-shimmer {
        gap: 52px;
        grid-template-columns: repeat(4, calc(25% - 24px));
      }
    }
  }
}

@media only screen and (min-width: 1440px) {
  .favourites-wrapper-shimmer {
    .favourites-container-shimmer {
      .favourites-card-shimmer {
        gap: 32px;
        grid-template-columns: repeat(4, calc(25% - 24px));
      }
    }
  }
}
