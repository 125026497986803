@import "../../styles/mixins.scss";

.fulfilment-type-selector-wrapper {
  display: flex;
  width: 100%;
  margin-top: 8px;
  justify-content: space-around;

  .fulfilment-value {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    cursor: pointer;
  }

  // .active {

  // }
  // .fulfilment-type-button {
  //   @include align-center;
  //   cursor: pointer;
  //   padding: 4px 8px;
  //   gap: 8px;
  //   width: 80px;
  //   min-height: 30px;
  //   .fulfilment-type-icon {
  //     width: 20px;
  //     height: auto;
  //     margin-inline-end: 8px;
  //     display: block;
  //     display: none;
  //   }
  //   .chevron-icon {
  //     width: 12px;
  //     height: auto;
  //     margin-inline-end: 5px;
  //   }
  // }
}
.fulfilment-type-dialog-root {
  width: 100%;
  .fulfilment-type-dialog-container {
    align-items: flex-end !important;
    justify-content: flex-start;
    .fulfilment-type-dialog-paper {
      width: 100%;
      height: 20%;
      margin: 0px;
      padding: 16px;
      border-radius: 8px 8px 0 0;
      z-index: 100;
      .fulfilment-type-title-wrapper {
        display: flex;
        justify-content: space-between;
        padding: 24px 0;
        .close-dialog-icon {
          width: 16px;
          height: 16px;
        }
      }
      .fulfilment-type-list {
        .fulfilment-type {
          display: flex;
          cursor: pointer;
          padding: 8px 0px;
          align-items: center;
          justify-content: flex-start;
          .fulfilment-type-icon {
            width: 20px;
            height: auto;
            margin-inline-end: 8px;
          }
        }
        .fulfilment-type-value-list {
          .fulfilment-type-value {
            margin-inline-end: 0px;
            display: flex;
            flex-direction: row-reverse;
            .checkbox-icon,
            .radio-icon {
              width: 16px;
              height: 16px;
            }
            .fulfilment-type-label {
              width: 100%;
              .fulfilment-radio-label-wrapper {
                display: flex;
                align-items: center;
                margin-inline-start: 10px;
                .fulfilment-type-icon {
                  width: 20px;
                  height: auto;
                }

                .fulfilment-type-text {
                  margin-inline-start: 15px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .fulfilment-type-selector-wrapper {
    background-color: unset;
    border: none;
    border-radius: none;
    .fulfilment-type-button {
      max-width: 120px;
      padding: 2px 8px;
      justify-content: flex-start;
      width: 100%;
      gap: unset;
      .fulfilment-type-icon {
        margin-inline-end: 12px;
        display: block;
      }
      .chevron-icon {
        width: 16px;
        margin-inline-start: 8px;
      }
    }
  }
  .fulfilment-type-dialog-root {
    top: 110px !important;
    .MuiBackdrop-root {
      opacity: 0 !important;
      transform: none !important;
      transition: none !important;
    }
    .fulfilment-type-dialog-container {
      transform: none !important;
      transition: none !important;
      align-items: flex-start !important;
      .fulfilment-type-dialog-paper {
        width: auto;
        height: auto;
        margin: 0px;
        min-width: 160px;
        border-radius: 8px;
        box-shadow: 0 4px 6px 4px rgba(163, 163, 163, 0.16);
        .fulfilment-type-title-wrapper {
          display: none;
          .close-dialog-icon {
            display: none;
          }
        }
      }
    }
  }
}
