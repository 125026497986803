@import "../../styles/colors.scss";
@import "../../styles/mixins.scss";

@mixin button-style {
  padding: 4px 0px;
  @media only screen and (min-width: 768px) {
    padding: 6px 0px;
  }
}

.component-header-bg {
  height: 284px;
  border-radius: 4px;
}
.generic-form-component-wrapper {
  margin: 15px 0;
  .generic-form-component {
    box-shadow: 2px 2px 20px -4px rgb(0 0 0 / 20%);
    background-color: $white-color;
    width: 50%;
    margin: 0 auto;
    padding: 25px;
    border-radius: 8px;
    position: relative;
    top: -142px;
    .dialog-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .close-dialog-icon {
        cursor: pointer;
      }
    }
    .dialog-content {
      margin-top: 12px;
      padding: 12px 0px;
      border-radius: 12px;
    }
    .dialog-footer {
      .form-submit-button {
        border-radius: 12px;
        @include button-style;
        text-transform: capitalize;
        .form-submit-button-text {
          color: $white-color;
        }
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .generic-form-component-wrapper {
    .generic-form-component {
      .dialog-header {
        .form-heading {
          font-size: 24px;
        }
      }
      .dialog-content {
        margin-top: 18px;
      }
    }
  }
}
