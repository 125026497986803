@import "./colors.scss";

body {
  margin: 0px;
}

@font-face {
  font-style: normal;
  font-display: swap;
}

// for responsive design with rem
html {
  font-size: 14px;

  @media only screen and (min-width: 768px) {
    font-size: initial;
  }
}

.MuiPaper-root {
  border-radius: 16px;
}

// container width
.container {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
}

.error-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (min-width: 768px) {
  .container {
    padding: 0px;
  }
}
@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1050px;
  }
}
@media only screen and (min-width: 1440px) {
  .container {
    max-width: 1200px;
  }
}

// shimmer
div[class^="Shimmer-shimmer"],
div[class*=" Shimmer-shimmer"] {
  display: block;
  background-size: 800px 100%;
}

// hide arrows if input type number
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

// freshcat widget
.fc-mobile-view {
  bottom: 120px !important;
}

//normalize scroll for all browsers and operating systems

// for chrome, edge, safari
::-webkit-scrollbar {
  width: 0.5em; //vertical scrollbar width
  height: 0.5em; //horizontal scrollbar height
}

::-webkit-scrollbar-track {
  background-color: $white-color;
}

::-webkit-scrollbar-thumb {
  background-color: $scrollbar-gray-color;
  border-radius: 5px;
}

// for firefox
@supports (scrollbar-width: thin) {
  * {
    scrollbar-width: thin;
    scrollbar-color: $scrollbar-gray-color;
  }
}
