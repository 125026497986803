@import "../../../styles/colors.scss";
@import "../../../styles/mixins.scss";

.cart-wrapper-shimmer {
  @include wrapper-shimmer;
  background-color: $alabaster-color;
  .cart-container-shimmer {
    @include container-shimmer;
    background-color: $alabaster-color;
    display: flex;
    flex-direction: column;
    .cart-left-section-shimmer {
      width: 100%;
      > div {
        background-color: $white-color;
      }
      > div:nth-child(2) {
        border-radius: 8px;
      }
      .cart-widget-shimmer {
        padding: 24px;
      }
      .cart-header-shimmer {
        @include header-shimmer;
        margin-bottom: 34px;
        width: 40%;
      }
      .item-update-placeholder {
        display: flex;
        margin-top: 32px;
        align-items: flex-start;
        justify-content: space-between;
        flex-direction: column;
        .image-wrapper {
          display: flex;

          .item-image-placeholder {
            width: 100px;
            height: 60px;
            border-radius: 16px;
          }
          .item-update-placeholder-wrapper {
            margin-inline-start: 20px;
            display: flex;
            flex-direction: column;
            .placeholder-1 {
              width: 180px;
              height: 18px;
              margin-bottom: 20px;
              border-radius: 20px;
            }
            .placeholder-2 {
              width: 70px;
              height: 10px;
              border-radius: 20px;
            }
          }
        }
        .counter-add-wrapper {
          flex-grow: 3;
          width: 100%;
          align-items: center;

          .counter-placeholder {
            width: 90px;
            height: 36px;
            border-radius: 8px;
            margin-left: auto;
          }
        }
      }
      .cart-add-more-shimmer {
        @include header-shimmer;
        width: 60%;
        border-radius: 16px;
        margin-top: 30px;
      }
    }
    .cart-right-section-shimmer {
      width: 100%;
      background-color: $white-color;
      padding: 20px;
      height: max-content;
      padding-bottom: 32px;
      margin: 0;
      .cart-header-shimmer {
        @include header-shimmer;
        margin-bottom: 42px;
        width: 40%;
      }
      .cart-bill-shimmer-wrapper {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        .cart-bill-text-one-shimmer {
          height: 10px;
          width: 50%;
          border-radius: 16px;
        }
        .cart-bill-text-two-shimmer {
          height: 10px;
          border-radius: 16px;
          width: 20%;
        }
      }
      .cart-bill-shimmer-wrapper:nth-child(4) {
        margin-top: 40px;
        .cart-bill-text-one-shimmer {
          height: 20px;
        }
        .cart-bill-text-two-shimmer {
          height: 20px;
        }
      }
      .cart-bill-text-three-shimmer {
        height: 10px;
        border-radius: 16px;
        width: 60%;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .cart-wrapper-shimmer {
    .cart-container-shimmer {
      justify-content: space-between;
      .cart-left-section-shimmer {
        .item-update-placeholder {
          flex-direction: row;
          align-items: center;
          .quality-price-wrapper {
            width: 200px;
            flex-direction: row;
            margin-top: 0;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .cart-wrapper-shimmer {
    .cart-container-shimmer {
      flex-direction: row;
      gap: 20px;
      .cart-left-section-shimmer {
        width: 59%;
      }
      .cart-right-section-shimmer {
        width: 39%;
      }
    }
  }
}
