@import "../../../styles/colors.scss";
@import "../../../styles/mixins.scss";

.order-history-wrapper {
  @include wrapper-shimmer;
  .orders-card {
    @include container-shimmer;
    padding: 24px 12px !important;
    overflow-y: auto;
    margin: 0px;
    cursor: pointer;
  }
}

.order-integrated {
  .order-header-shimmer {
    @include header-shimmer;
    margin-bottom: 28px;
  }
  .order-card-shimmer {
    padding: 12px;
    border-radius: 16px;
    background-color: $white-color;
    box-shadow: 0 4px 6px 4px rgba(163, 163, 164, 0.1);
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .order-shimmer-left-section {
      width: 70%;
      .order-card-sub-one-shimmer {
        @include description-shimmer;
        width: 100%;
      }
      .order-card-sub-two-shimmer {
        @include description-shimmer;
        width: 70%;
      }
      .order-card-sub-three-shimmer {
        margin-top: 60px;
        @include description-shimmer;
        width: 120%;
      }
    }
    .order-shimmer-right-section {
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      align-items: center;
      .order-status-shimmer-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        .order-status-shimmer {
          border-radius: 40px;
          height: 40px;
          width: 40px;
        }
      }

      .order-price-shimmer {
        @include description-shimmer;
        margin-bottom: 0;
        width: 100px;
      }
    }
  }
}

// .order-not-integrated {
//   .order-wrapper-shimmer {
//     @include wrapper-shimmer;
//     padding-top: 24px;
//     .order-container-shimmer {
//       @include container-shimmer;
//       padding: 12px !important;
//       .order-header-shimmer {
//         @include header-shimmer;
//       }
//       .order-card-shimmer {
//         padding: 24px;
//         border-radius: 16px;
//         box-shadow: 0 4px 6px 4px rgba(163, 163, 164, 0.1);
//         margin-bottom: 24px;
//         .order-card-sub-one-shimmer {
//           @include description-shimmer;
//           width: 100%;
//         }
//         .order-card-sub-two-shimmer {
//           @include description-shimmer;
//           width: 80%;
//         }
//         .order-card-sub-three-shimmer {
//           @include description-shimmer;
//           width: 60%;
//         }
//       }
//     }
//   }
// }

@media only screen and (min-width: 768px) {
  .order-history-wrapper {
    padding-top: 24px;
    .orders-card {
      padding: 24px !important;
      margin: 0px 32px;
    }
  }
  .order-integrated {
    padding-top: 0;
    .order-card-shimmer {
      flex-direction: row;

      .order-shimmer-left-section {
        width: 70%;
        .order-card-sub-one-shimmer {
          @include description-shimmer;
          width: 60%;
        }
        .order-card-sub-two-shimmer {
          @include description-shimmer;
          width: 40%;
        }
        .order-card-sub-three-shimmer {
          margin-top: 60px;
          @include description-shimmer;
          width: 60%;
        }
      }

      .order-shimmer-right-section {
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        .order-status-shimmer {
          margin-bottom: 30px;
        }
      }
    }
  }
  // .order-not-integrated {
  //   .order-wrapper-shimmer {
  //     .order-container-shimmer {
  //       padding: 24px !important;
  //     }
  //   }
  // }
}
@media only screen and (min-width: 1200px) {
  .order-history-wrapper {
    .orders-card {
      margin: 0px;
    }
  }
}
