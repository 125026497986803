@import "../../styles/colors.scss";

.action-button-wrapper {
  padding: 10px 16px;
  border-radius: 18px;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  box-shadow: 0px 4px 6px 4px rgba(163, 163, 163, 0.16);
  cursor: pointer;
  box-shadow: 0 4px 6px 4px rgba(163, 163, 163, 0.16);
  .button-label {
    color: $white-color;
  }
  background-color: #000;
}
