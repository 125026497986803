@import "../../../styles/mixins.scss";
@import "../../../styles/colors.scss";

.order-success-wrapper-shimmer {
  @include wrapper-shimmer;
  min-height: calc(100vh - 300px) !important;
  .order-success-container-shimmer {
    @include container-shimmer;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: transparent !important;
    .order-success-section-shimmer {
      flex: 1;
      background-color: $white-color;
      padding: 30px;
      display: grid;
      gap: 20px;
      place-content: center;
      place-items: center;
      .order-success-image-shimmer {
        border-radius: 16px;
        height: 20vh;
        width: 30vh;
      }
      .order-success-text-one {
        height: 20px;
        width: 300px;
        border-radius: 16px;
      }
      .order-success-group-texts {
        width: 600px;
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        align-items: center;
        .order-success-text-two {
          height: 10px;
          width: 150px;
          border-radius: 16px;
          margin-bottom: 20px;
        }
        .order-success-text-three {
          height: 10px;
          width: 150px;
          border-radius: 16px;
          margin-bottom: 20px;
        }
        .order-success-text-four {
          height: 10px;
          width: 150px;
          border-radius: 16px;
          margin-bottom: 20px;
        }
      }
    }
    .order-success-summary-shimmer {
      display: flex;
      flex-direction: column;
      gap: 20px;
      flex: 1;
      .order-success-summary-one-shimmer {
        // flex: 2;
        background-color: $white-color;
        padding: 30px;
        // display: grid;
        // gap: 20px;
        width: 100%;
        .order-success-group-one {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          margin-bottom: 50px;
          .order-success-first {
            width: 30%;
            margin-bottom: 50px;
            .order-success-text-one {
              height: 20px;
              width: 100%;
              border-radius: 16px;
              margin-bottom: 50px;
            }
          }
          .order-success-second {
            width: 100%;
            margin-inline-end: 30px;

            .order-success-text-two {
              height: 15px;
              width: 100%;
              border-radius: 16px;
              margin-bottom: 50px;
              // max-width: 300px;
            }
          }
        }
      }
      .order-success-summary-two-shimmer {
        // flex: 1;
        background-color: $white-color;
        padding: 30px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        // display: grid;
        // gap: 20px;
        .order-success-summary-two-group {
          display: flex;
          justify-content: center;
          margin-bottom: 30px;
          .order-success-text-one {
            height: 50px;
            width: 50px;
            border-radius: 16px;
            margin-inline-end: 10px;
          }
        }
        .order-success-summary-three-group {
          width: 100%;
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          .order-success-text-two {
            height: 30px;
            max-width: 300px;
            border-radius: 16px;
            margin-bottom: 30px;
            width: 70%;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .order-success-wrapper-shimmer {
    .order-success-container-shimmer {
      .order-success-summary-shimmer {
        flex-direction: row;
        .order-success-summary-one-shimmer {
          width: 70%;
          .order-success-group-one {
            flex-direction: row;
            .order-success-second {
              width: 60%;
              margin-top: 20px;
            }
          }
        }
        .order-success-summary-two-shimmer {
          width: 28%;
        }
      }
      .order-success-section-shimmer {
        .order-success-group-texts {
          flex-direction: row;
        }
      }
    }
  }
}
