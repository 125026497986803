@import "../../styles/colors.scss";

.base-layout-container {
  width: 100%;
  min-height: 100%;
  position: relative;
  padding-bottom: 48px;
  .view-wrapper {
    overflow-y: auto;
    flex: auto;
    transition: 0.5s;
  }
}

.disable-pointer-events {
  pointer-events: none;
}

.customize-theme-bar {
  max-width: 100vw;
  width: 100%;
  background-color: white;
  position: fixed;
  bottom: 0;
  z-index: 999;
  box-sizing: border-box;
}

@media only screen and (min-width: 768px) {
  .base-layout-container {
    padding-bottom: 0;
    .view-wrapper {
      min-height: calc(100vh - 300px);
    }
  }
}
