@import "../../../styles/colors.scss";
@import "../../../styles/mixins.scss";

.explore-wrapper-shimmer {
  @include wrapper-shimmer;
  background-color: $white-color;
  .explore-container-shimmer {
    @include container-shimmer;
    .explore-hero-shimmer {
      height: 45vh;
      border-radius: 16px;
    }
    .explore-menu-shimmer {
      margin-top: 42px;
    }
    .item-wrapper-shimmer {
      .item-container-shimmer {
        .item-header-shimmer {
          @include header-shimmer;
        }
        .item-card-shimmer {
          display: flex;
          flex-direction: column;
          gap: 30px;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .explore-wrapper-shimmer {
    .explore-container-shimmer {
      .explore-hero-shimmer {
        margin: 20px 0;
        height: 70vh;
        border-radius: 16px;
      }
      .explore-menu-shimmer {
        height: 42px;
        border-radius: 8px;
        margin-bottom: 32px;
      }
      .item-wrapper-shimmer {
        min-height: calc(100vh - 300px);
        .item-container-shimmer {
          .item-card-shimmer {
            padding: 24px 0px;
            display: grid;
            grid-template-columns: calc(50% - 30px) calc(50% - 30px);
            grid-gap: 24px;
            box-sizing: border-box;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .explore-wrapper-shimmer {
    .explore-container-shimmer {
      .item-wrapper-shimmer {
        .item-container-shimmer {
          .item-card-shimmer {
            padding: 32px 0px;
            grid-template-columns: calc(33.33% - 30px) calc(33.33% - 30px) calc(
                33.33% - 30px
              );
            grid-gap: 32px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .explore-wrapper-shimmer {
    .explore-container-shimmer {
      .item-wrapper-shimmer {
        .item-container-shimmer {
          .item-card-shimmer {
            grid-template-columns:
              calc(25% - 24px) calc(25% - 24px) calc(25% - 24px)
              calc(25% - 24px);
          }
        }
      }
    }
  }
}
