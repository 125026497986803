@import "../../styles/mixins.scss";
@import "../../styles/colors.scss";

.item-badge-wrapper {
  @include align-center;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  position: absolute;
  padding: 0px 6px;
  top: 8px;
  left: 0;
  .badge-text {
    text-align: center;
    color: $black-squeeze;
    text-transform: capitalize;
  }
}

.bestseller {
  background-color: $cinnabar-color;
}

.featured {
  background-color: $burning-orange;
}

@media only screen and (min-width: 768px) {
  .item-badge-wrapper {
    min-width: 100px;
    height: 30px;
    top: 10px;
    left: 0px;
    .badge-text {
      font-size: 14px;
      line-height: 1.71;
    }
  }
}
