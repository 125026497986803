.menu-section-wrapper {
  padding: 42px 0px;
  @media only screen and (min-width: 768px) {
    padding: 42px 0px 0px 0px;
  }
  .section-title {
    text-align: center;
    text-transform: uppercase;
  }
  .separator {
    display: flex;
    margin: 24px auto;
  }
  .section-description {
    margin: 0 auto;
    max-width: 800px;
    text-align: center;
  }
  .menu-button {
    width: 200px;
    padding: 8px 10px;
    margin: 24px auto 48px auto;
    .button-icon {
      margin-inline-start: 10px;
    }
  }

  .category-1 {
  }
  .category-2 {
  }
  .category-tile {
    background-size: cover;
    height: 220px;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    transition: 0.3s;
    margin: 10px;
    cursor: pointer;
    @media only screen and (min-width: 768px) {
      height: 300px;
    }
    &:hover {
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
    .text-wrapper {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      background: linear-gradient(
        8deg,
        rgba(0, 0, 0, 0.5) 0%,
        rgba(0, 0, 0, 0.11) 36%,
        rgba(0, 0, 0, 0) 46%
      );
      .category-name {
        padding: 12px;
      }
    }
  }
}
