@import "../../../styles/colors.scss";
@import "../../../styles/mixins.scss";

.profile-wrapper-shimmer {
  @include wrapper-shimmer;
  border: 1px solid transparent;
  .profile-container-shimmer {
    @include container-shimmer;
    background-color: transparent !important;
    .profile-details-shimmer {
      background-color: $white-color;
      padding: 16px;
      .profile-header-shimmer {
        height: 25px;
        width: 100px;
        border-radius: 16px;
      }
      .profile-section-heading-shimmer {
        height: 16px;
        width: 150px;
        border-radius: 16px;
        margin-top: 30px;
        margin-bottom: 30px;
        display: block;
      }
      .profile-card-container-shimmer {
        margin-top: 20px;
        .profile-card-shimmer {
          margin-bottom: 50px;
          display: flex;
          align-items: center;
          .placeholder-one-shimmer {
            height: 28px;
            border-radius: 16px;
            width: 100%;
            // margin-bottom: 22px;
          }
          .placeholder-two-shimmer {
            height: 3rem;
            border-radius: 1rem;
            width: 3.5rem;
            margin-inline-end: 10px;
          }
        }
      }
    }
    .profile-offers-shimmer {
      background-color: $white-color;
      padding: 16px;
      .profile-header-shimmer {
        @include header-shimmer;
        margin: 0 auto 32px;
      }
      .profile-offers-coupons-shimmer {
        height: 130px;
        width: 100%;
        border-radius: 16px;
        margin-bottom: 24px;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .profile-wrapper-shimmer {
    .profile-container-shimmer {
      display: flex;
      justify-content: space-between;
      gap: 12px;
      .profile-details-shimmer {
        padding: 24px;
        flex: 1.88;
        .profile-card-container-shimmer {
          .profile-card-shimmer {
            margin-bottom: 50px;
          }
        }
      }
      .profile-offers-shimmer {
        flex: 1;
        padding: 24px !important;
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .profile-wrapper-shimmer {
    .profile-container-shimmer {
      .profile-details-shimmer {
        padding: 32px;
        .profile-card-container-shimmer {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-wrap: wrap;
          .profile-card-shimmer {
            margin-inline-end: 20px;
            .placeholder-two-shimmer {
              width: 4rem;
            }
          }
        }
        .profile-card-shimmer {
          margin-bottom: 50px;
          width: 45%;
        }
      }
      .profile-offers-shimmer {
        padding: 32px !important;
      }
    }
  }
}
