@import "../../styles/colors.scss";
@import "../../styles//mixins.scss";

.custom-page-wrapper {
  @include wrapper-shimmer;
  padding-top: 24px;
  .custom-content-container {
    @include container-shimmer;
    padding: 12px !important;
    min-height: calc(100vh - 200px) !important;
    box-sizing: border-box;
    background-color: $white-color;
  }
}

@media only screen and (min-width: 768px) {
  .custom-page-wrapper {
    .custom-content-container {
      padding: 24px !important;
    }
  }
}
