@import "../../styles/mixins.scss";
@import "../../styles/colors.scss";

.multiple-store-form {
  min-height: 200px;
  padding-bottom: 50px;
  .dialog-header {
    display: flex;
    align-items: center;
    padding: 24px 16px 0px 16px;
    justify-content: space-between;
    .form-heading {
      &::first-letter {
        text-transform: uppercase;
      }
    }
    .close-dialog-icon {
      cursor: pointer;
    }
  }
  .form-sub-heading {
    padding: 0px 16px;
    margin-top: 5px;
  }
  .dialog-content {
    .store-list {
      margin: 0px 0px 16px;
      padding: 8px 0px 0px;
      list-style-type: none !important;
      .store-details {
        cursor: pointer;
        padding: 16px;
        border-bottom: 1px solid rgba(163, 163, 164, 0.16);
        .store-header {
          display: flex;
          align-items: center;
          margin-bottom: 5px;
          justify-content: space-between;
          .store-distance {
            margin-inline-start: 5px;
          }
        }
        .store-address,
        .store-phone {
          line-height: 21px;
        }
        &:last-child {
          border-bottom: 0px;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .multiple-store-form {
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 0px;
    height: 450px;
    .dialog-header {
      padding: 24px 24px 0px 24px;
    }
    .form-sub-heading {
      padding: 0px 24px;
    }
    .dialog-content {
      .store-list {
        .store-details {
          padding: 16px 24px;
          > * {
            pointer-events: none; // to make sure children dont inherit background color
          }
          &:hover {
            .store-header > * {
              text-shadow: 0.5px 0 0;
            }
          }
        }
      }
    }
  }
}
