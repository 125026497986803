.diet-indicator-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .diet-indicator {
    margin-inline-end: 8px;
  }
  .diet-name {
    font-size: 12px;
  }
}
