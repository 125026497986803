@import "../../../styles/mixins.scss";
@import "../../../styles/colors.scss";

.brand-value-section-wrapper {
  padding: 72px 16px;
  .brand-value-container {
    @media only screen and (min-width: 768px) {
      display: flex;
    }
    .brand-value {
      max-width: 260px;
      margin: 0 auto;
      @include align-center;
      flex-direction: column;
      padding-bottom: 24px;
      @media only screen and (min-width: 768px) {
        max-width: 320px;
      }
      .value-icon {
        width: 28px;
        height: auto;
        margin: 12px auto;
        @media only screen and (min-width: 768px) {
          width: 32px;
          margin: 18px auto;
        }
      }
      .value-title {
        margin: 5px auto;
        @media only screen and (min-width: 768px) {
          margin: 12px auto;
        }
      }
      .value-description {
        margin: 5px auto;
        text-align: center;
        line-height: 20px;
      }
    }
  }
}
