@import "../../../styles/mixins.scss";
@import "../../../styles/colors.scss";

.add-address-wrapper {
  height: 100%;
  background-color: $white-color;
  .search-address-heading {
    display: flex;
    align-items: center;
    .search-address-input {
      width: 100%;
      padding: 8px;
      box-shadow: 0 4px 6px 4px rgba(163, 163, 164, 0.1);
      .checkron-back-icon {
        margin-inline-end: 8px;
        cursor: pointer;
      }
      .clear-search-icon {
        cursor: pointer;
      }
    }
  }
  .add-address-heading {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 4px 6px 4px rgba(163, 163, 164, 0.1);
    margin-bottom: 2px;
    .add-address-heading-left {
      display: flex;
      align-items: center;
      .checkron-back-icon {
        margin-inline-end: 16px;
        cursor: pointer;
      }
    }
    .add-address-heading-right {
      .close-address-drawer-icon {
        @include align-center;
        cursor: pointer;
      }
    }
  }
  .add-address-content {
    height: 40vh;
    .select-location-map-wrapper {
      width: 100%;
      height: 100%;
      position: relative;
      .select-location-marker {
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 45%;
      }
    }
  }
  .selected-address-info {
    padding: 24px;
    background-color: $white-color;
    cursor: pointer;

    .heading-wrapper {
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding-bottom: 8px;
    }
    .set-location-cta {
      height: 56px;
      margin: 8px auto;
      width: 98%;
    }
  }
  .search-address-content {
    background-color: $white-color;
    .close-search-address-icon {
      cursor: pointer;
    }
    .address-suggestions-wrapper {
      background-color: $white-color;
      box-sizing: border-box;
      border-radius: 4px;
      width: 100%;
      overflow-y: auto;
      .address-options {
        cursor: pointer;
        padding: 8px 16px;
        border-bottom: 1px solid rgba(163, 163, 164, 0.16);
        > * {
          pointer-events: none; // to make sure children dont inherit background color
        }
        .address-title {
          margin: 5px 0px;
          text-transform: uppercase;
          line-height: 1.45;
          letter-spacing: 1px;
        }
        .address {
          max-width: 280px;
        }
        &:hover {
          .address-title {
            text-shadow: 0.5px 0 0;
          }
        }
      }
    }
  }
  .add-address-footer {
    position: relative;
    padding-bottom: 12px;
    .save-button {
      padding: 8px 0px;
      width: 96%;
      margin: 10px auto;
    }
    .add-address-form {
      transition: all 1000ms ease-in;
      background-color: $white-color;
      padding: 0 16px 16px;
      .address-location-tag-label {
        font-size: 12px !important;
        margin-inline-start: 8px;
        margin-bottom: 12px;
      }
      .address-tag-tabs {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 24px;
        margin-bottom: 24px;
        margin-inline-start: 8px;
        .address-tag-tab {
          border: 1px solid $santas-gray;
          border-radius: 8px;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 0px 8px;
          > .address-icon {
            width: 52px;
            height: 52px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .add-address-wrapper {
    .add-address-heading {
      padding: 24px;
    }
    .add-address-footer {
      overflow-y: auto;
      background-color: $white-color;
      .add-address-form {
        .address-tag-tabs {
          .address-tag-tab {
            padding: 8px 24px;
            > .address-icon {
              width: 52px;
              height: 52px;
            }
          }
        }
      }
    }
    .selected-address-info {
      background-color: $white-color;
      position: static;
      .address-tag-tab {
        padding: 8px 16px;
      }
    }
  }
}
