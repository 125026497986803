@import "../../../../styles/colors.scss";

.recommended-item {
  width: 100%;
  height: 100%;
  border-bottom: 0px;
  margin-bottom: 24px;
  cursor: pointer;
  transition: 0.3s;
  background-color: $alabaster-color;
  &:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .item-image {
    width: 100%;
    height: 260px;
    display: flex;
    justify-content: center;
    background-size: cover;
    flex-direction: column;
    align-items: center;
    object-fit: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .item-name {
    text-align: center;
    padding: 8px;
    text-transform: capitalize;
  }
  @media only screen and (min-width: 768px) {
    width: 100%;
  }
  @media only screen and (min-width: 768px) {
    width: 100%;
  }
}
