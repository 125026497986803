@import "../../styles/mixins.scss";

.h1,
.h2 {
  @include no-margin;
  font-display: swap;
}

.h3,
.h4 {
  @include no-margin;
  line-height: 24px;
  font-display: swap;
}

.para {
  @include no-margin;
  font-size: 11px;
  line-height: 16px;
  font-display: swap;
}

.input-required {
  color: red;
}
