@import "../../../styles/mixins.scss";
@import "../../../styles/colors.scss";

.date-field-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  &:last-child {
    margin-bottom: 0px;
  }
  .field-icon-wrapper {
    @include align-center;
    width: 54px;
    height: 54px;
    min-width: 54px;
    border-radius: 16px;
    margin-inline-end: 20px !important;
    .field-icon {
      width: 18px;
      height: 18px;
    }
  }
  .date-field {
    width: 100%;
    label {
      font-size: 9px;
      text-transform: uppercase;
      line-height: 24px;
      letter-spacing: 1px;
    }
    .MuiInputBase-root,
    .MuiInputBase-root .MuiInputAdornment-root .MuiTypography-root {
      font-size: 16px;
      line-height: 32px;
      color: #626264 !important;
    }
    .MuiFormHelperText-root {
      color: $cinnabar-color;
      @include field-error;
    }
    .MuiInput-underline:before,
    .MuiInput-underline:after {
      border-bottom: 2px solid rgba(163, 163, 164, 0.16);
    }
    .MuiInput-underline.Mui-error:before,
    .MuiInput-underline.Mui-error:after {
      border-bottom-color: $cinnabar-color;
    }
  }
}
.disabled {
  opacity: 0.4;
  pointer-events: none;
  .MuiInput-underline:after,
  .MuiInput-underline:before {
    border-bottom: 0px !important;
  }
}

@media only screen and (min-width: 768px) {
  .date-field-wrapper {
    margin-bottom: 24px;
    width: 50%;
    min-width: 250px;
    .date-field {
      label {
        font-size: 11px;
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .date-field-wrapper {
    .date-field {
      label {
        font-size: 12px;
      }
    }
  }
}
