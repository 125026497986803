@import "../../styles/mixins.scss";
@import "../../styles/colors.scss";

.counter-wrapper {
  width: 64px;
  height: 32px;
  display: flex;
  align-items: center;
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  user-select: none;
  cursor: auto;
  justify-content: space-around;
  .incrementor,
  .decrementor {
    width: 18px;
    padding: 5px;
    height: auto;
    cursor: pointer;
  }
  .animate-incrementor {
    animation: goRight 0.2s ease;
  }
  .animate-decrementor {
    animation: goLeft 0.2s ease;
  }
  .incrementor,
  .decrementor,
  .item-count {
    @include align-center;
  }

  .animateAdd {
    animation: jumpFromTop 0.4s ease;
  }
  .animateRemove {
    animation: jumpFromBottom 0.4s ease;
  }
}

@keyframes goLeft {
  from {
    margin-left: 20px;
  }
  to {
    margin-left: 0;
  }
}

@keyframes goRight {
  from {
    margin-right: 20px;
  }
  to {
    margin-right: 0;
  }
}

@keyframes jumpFromTop {
  0% {
    margin-top: -20px;
  }
  50% {
    margin-top: 10px;
  }
  100% {
    margin-top: 0;
  }
}
@keyframes jumpFromBottom {
  0% {
    margin-top: 20px;
  }
  50% {
    margin-top: -10px;
  }
  100% {
    margin-top: 0;
  }
}
