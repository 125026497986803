@import "../../styles/colors.scss";

.card-wrapper {
  padding: 16px;
  margin: 0px 0px 16px 0px;
  background-color: $white-color;
  &:first-child {
    margin-inline-start: 0px;
  }
  &:last-child {
    margin-inline-end: 0px;
    margin-bottom: 0px;
  }
}

.disabled-card {
  height: 60px;
  opacity: 0.6;
  display: flex;
  align-items: center;
  pointer-events: none;
}

@media only screen and (min-width: 768px) {
  .card-wrapper {
    padding: 32px;
    margin: 0px 12px 0px 12px;
  }
}
