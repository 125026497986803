@import "../../../styles/colors.scss";
@import "../../../styles/mixins.scss";

.coupons-wrapper-shimmer {
  @include wrapper-shimmer;
  min-height: calc(100vh - 200px) !important;
  .coupons-container-shimmer {
    @include container-shimmer;
    background-color: $alabaster-color !important;
    .coupons-header-shimmer {
      @include header-shimmer;
    }
    .coupons-items-shimmer {
      margin-top: 32px;
      .coupons-solo-shimmer {
        height: 144px;
        padding: 16px;
        border-radius: 10px;
        margin-bottom: 24px;
        box-sizing: border-box;
        background-color: $alabaster-color;
        border: 1px dashed $santas-gray;
      }
    }
    @media only screen and (min-width: 768px) {
      .coupons-items-shimmer {
        display: grid;
        grid-row-gap: 0;
        row-gap: 0;
        column-gap: 24px;
        grid-template-columns: calc(50% - 16px) calc(50% - 16px);
      }
    }
    @media only screen and (min-width: 1024px) {
      .coupons-items-shimmer {
        display: grid;
        grid-row-gap: 0;
        row-gap: 0;
        grid-column-gap: 24px;
        column-gap: 24px;
        grid-template-columns: calc(33.33% - 16px) calc(33.33% - 16px) calc(
            33.33% - 16px
          );
      }
    }
  }
}
