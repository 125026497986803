@import "../../../styles/colors.scss";
@import "../../../styles/mixins.scss";

.route-shimmer {
  padding: 0;
}
.item-details-wrapper-shimmer {
  @include wrapper-shimmer;
  background-color: $white-color !important;
  .item-details-container-shimmer {
    @include wrapper-shimmer;
    background-color: $white-color !important;
    width: 95%;
    .item-content-placeholder-shimmer {
      .image-wrapper-shimmer {
        .item-img-placeholder-shimmer {
          width: 100%;
          height: 100%;
          min-height: 280px;
          max-height: 280px;
        }
      }
      .item-info-placeholder-shimmer {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin: 20px 16px;
        .item-info-description-wrapper {
          width: 100%;
          .description-placeholder-shimmer-one {
            height: 20px;

            margin-bottom: 36px;
            border-radius: 20px;
          }

          .description-placeholder-shimmer-two {
            height: 12px;
            border-radius: 20px;
            margin: 10px 0;
          }
          .description-price-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-right: 20px;
          }
        }
      }
    }
  }
  .recommendation-placeholder-carousel-shimmer {
    margin: 30px 0;
  }
}

@media only screen and (min-width: 768px) {
  .route-shimmer {
    padding: 24px 12px 12px 12px;
  }
  .item-details-wrapper-shimmer {
    .item-details-container-shimmer {
      .item-content-placeholder-shimmer {
        display: flex;
        .image-wrapper-shimmer {
          width: 55%;
          margin-top: 0;
          .item-img-placeholder-shimmer {
            width: 100%;
            height: 100%;
            min-height: 260px;
            max-height: 320px;
          }
        }
        .item-info-placeholder-shimmer {
          width: 45%;
          display: flex;
          align-items: center;
          flex-direction: column;
          margin-top: 0;
          .item-info-description-wrapper {
            width: 95%;
            margin-top: 20px;
            .description-placeholder-shimmer-one {
              height: 20px;
              display: block;
              margin-bottom: 36px;
              border-radius: 20px;
            }

            .description-price-wrapper {
              display: flex;
              justify-content: space-between;
              .description-placeholder-shimmer-two {
                height: 36px;
                width: 64px;
                border-radius: 8px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1020px) {
  .item-details-wrapper-shimmer {
    .item-details-container-shimmer {
      .item-content-placeholder-shimmer {
        .image-wrapper-shimmer {
          width: 55%;
          .item-img-placeholder-shimmer {
            width: 100%;
            height: 100%;
            min-height: 360px;
            max-height: 420px;
          }
        }
        .item-info-placeholder-shimmer {
          width: 45%;
        }
      }
    }
  }
}
