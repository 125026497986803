@import "../../styles/colors.scss";
@import "../../styles/mixins.scss";

.explore-wrapper {
  box-sizing: border-box;
  @include wrapper-shimmer;
  height: 100%;
  overflow: hidden;
  background-color: $white-color;
  .explore-container {
    @include container-shimmer;
    padding: 0;
  }
}

// @media (max-width: 768px) {
//   .explore-wrapper {
//     margin-top: 117px;
//   }
// }
