@import "../../../styles/colors.scss";

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-button-prev {
  background-color: rgba(0, 0, 0, 0.6);
  width: 48px !important;
  height: 48px !important;
  border-radius: 10px;
  color: white !important;
  transform: translateY(-50%);
  :after {
    color: white !important;
    font-weight: bold !important;
    font-size: 15px !important;
  }
}

.swiper-button-next {
  background-color: rgba(0, 0, 0, 0.6);
  width: 48px !important;
  height: 48px !important;
  border-radius: 8px;
  color: white !important;
  transform: translateY(-50%);
  :after {
    color: white !important;
    font-size: 15px !important;
    font-weight: bold !important;
  }
}

.recommended-section-wrapper {
  padding: 42px 0px;
  margin-bottom: 18px;
  .recommended-items-container {
    .section-title {
      text-align: center;
      text-transform: uppercase;
    }
    .separator {
      display: flex;
      margin: 24px auto;
    }
    .recommended-item-list {
      @media only screen and (min-width: 768px) {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }
}
