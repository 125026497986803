@import "../../../styles/colors.scss";
@import "../../../styles/mixins.scss";

.store-locator-wrapper-shimmer {
  @include wrapper-shimmer;
  .store-locator-container-shimmer {
    @include container-shimmer;
    min-height: calc(100vh - 200px) !important;
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    .map-wrapper-shimmer {
      margin-top: 25px;
      .map-wrapper-two {
        flex: 3;
        .store-locator-map-shimmer {
          height: 40vh;
          width: 100%;
        }
      }
    }
    > div:nth-child(2) {
      flex: 2;
      padding: 12px;
      .store-locator-header-shimmer {
        @include header-shimmer;
        margin-bottom: 60px;
      }
      .store-locator-search-shimmer {
        @include description-shimmer;
        padding: 14px;
        margin-bottom: 24px !important;
      }
      .store-locator-card-shimmer {
        margin: 0px 4px 16px;
        border-radius: 12px;
        padding: 30px 52px;
        box-shadow: 0 4px 6px 4px rgba(163, 163, 164, 0.1);
        .store-locator-placeholder-one-shimmer {
          height: 10px;
          border-radius: 16px;
          margin-bottom: 14px !important;
          width: 50%;
        }
        .store-locator-placeholder-two-shimmer {
          height: 10px;
          border-radius: 16px;
          width: 60%;
          margin-bottom: 0 !important;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .store-locator-wrapper-shimmer {
    @include wrapper-shimmer;
    .store-locator-container-shimmer {
      @include container-shimmer;
      flex-direction: row;
      .map-wrapper-shimmer {
        width: 57%;
        margin-top: 0;
        .map-wrapper-two {
          height: 100%;
          padding: 0;
          .store-locator-map-shimmer {
            height: 100%;
            width: 100%;
          }
        }
      }
      > div:nth-child(2) {
        padding: 24px;
      }
      .store-locator-search-shimmer {
        width: 40%;
      }
    }
  }
}
