@import "../../styles/mixins.scss";
@import "../../styles/colors.scss";

.qr-code-customizable-button {
  padding: 0 8px;
}

.item-card-wrapper,
.item-placeholder-wrapper {
  padding: 22px 0;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: $white-color;
  border-bottom: 1px solid rgba(163, 163, 163, 0.32);
  .item-image-wrapper {
    position: relative;
    display: flex;
    width: 100%;
    flex-direction: row-reverse;

    .image-add-container {
      position: relative;
      .item-image,
      .image-placeholder {
        width: 136px;
        height: 100%;

        max-height: 100px;
        position: relative;
        display: flex;
        flex: 0 0 auto;
        object-fit: contain;
        align-items: center;
        border-radius: 10px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-color: $alabaster-color;
      }
      .right-action-wrapper {
        width: 100%;

        display: flex;
        justify-content: center;
        position: relative;
        top: -14%;
        .right-action {
          display: flex;

          flex-direction: column;
          justify-content: space-between;
          align-items: center;

          .button-wrapper {
            .out-of-stock-cta {
              width: 92px;
              height: 28px;
              white-space: nowrap;
            }
            .add-to-cart-cta,
            .counter-wrapper {
              box-shadow: 0px 2px 8px rgba(119, 119, 119, 0.16);
              height: 36px;
              width: 84px;
            }
          }
          .cutomize-button {
            font-size: 10px !important;
          }
        }
      }
    }
  }

  .item-content,
  .item-content-placeholder {
    width: 100%;
    display: flex;
    gap: 10px;
    bottom: 0;
    flex-direction: column;
    justify-content: flex-start;
    box-sizing: border-box;
    border-top: 0px !important;

    .card-title-wrapper,
    .item-title-placeholder {
      display: flex !important;
      // align-items: flex-start;
      justify-content: space-between;
      .product-name-wrapper {
        display: flex;
        align-items: center;
        .product-name {
          @include truncate(1);
          line-height: 1.3em;
          display: flex;
        }
      }
    }
    .item-description-wrapper {
      display: flex;
      align-items: center;
      margin-top: 4px;
      position: relative;
      width: 100%;
      overflow-x: hidden;
      .item-description {
        height: 70%;
        margin-inline-end: 5px;
        line-height: 1.4em;
        font-size: 14px;
        overflow-y: auto;
        overflow-x: hidden;
        width: 40vw;
      }
      .view-more-desc-link {
        display: none;
      }
    }
    .cutomize-button-wrapper {
      display: flex;
      justify-content: flex-end;
    }
    .pricing-details-wrapper {
      .price-wrapper {
        height: 24px;
        line-height: 24px;
        .original-item-price {
          vertical-align: middle;
          display: inline;
          text-decoration: line-through;
          padding-right: 5px;
        }
        .discounted-item-price {
          vertical-align: middle;
          display: inline;
          padding-right: 5px;
          font-weight: 600;
        }
      }
      .item-quantity {
        font-size: 12px;
        display: flex;
        align-items: center;
      }
    }
    .show-description-btn-mobile {
      position: absolute;
      right: 0;
      bottom: 3px;
      width: 120px;
      height: 20px;
      background: white;
    }
  }
  .show-description-btn {
    display: none;
  }
  .card-footer {
    // width: calc(100% - 110px);
    // bottom: 8px;
    // right: 0;
    // display: none;
    // position: absolute;
    align-items: center;
    justify-content: space-between;
    //padding: 10px;
    box-sizing: border-box;
    .pricing-details-wrapper {
      justify-content: space-between;
      .price-wrapper {
        min-width: 140px;
        .original-item-price {
          display: inline;
          text-decoration: line-through;
          color: $santas-gray;
          padding-right: 5px;
        }
        .discounted-item-price {
          display: inline;
          padding-right: 5px;
          font-weight: 600;
        }
      }
      .item-quantity {
        font-size: 12px;
        display: flex;
        min-width: 120px;
        align-items: center;
        font-weight: 500;
        padding: 5px 0;
        //  margin-top: -2px;
      }
    }
    .right-action {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .button-wrapper {
        .out-of-stock-cta {
          width: 92px;
          height: 28px;
          white-space: nowrap;
        }
        .add-to-cart-cta,
        .counter-wrapper {
          height: 26px;
        }
      }
      .cutomize-button {
        font-size: 10px !important;
      }
    }
  }
}

// .item-card-wrapper:first-of-type {
//   padding: 0 0 22px;
// }
.item-card-wrapper:last-of-type {
  border-bottom: unset;
}
@media only screen and (min-width: 768px) {
  .item-card-wrapper,
  .item-placeholder-wrapper {
    height: 100%;
    padding: 0px;
    border-bottom: 0px;
    border-radius: 16px;
    box-shadow: 0 4px 6px 4px rgba(163, 163, 164, 0.1);
    cursor: pointer;
    position: relative;

    .item-image-wrapper,
    .item-image-wrapper-placeholder {
      padding-bottom: 200px;
      min-height: 100px;
      display: block;
      .image-add-container {
        padding-bottom: 0;
        .right-action-wrapper {
          display: none;
        }
        .item-image,
        item-image-placeholder {
          width: 100%;
          height: 100%;
          max-height: 100%;
          position: relative;
          top: 0;
          border-bottom-left-radius: 0px;
          border-bottom-right-radius: 0px;
        }
      }
    }

    .item-content,
    .item-content-placeholder {
      .pricing-details-wrapper {
        display: none;
      }
      width: 100%;

      position: absolute;
      display: block;
      border-radius: 0px 0px 10px 10px;
      transition: all 0.5s;
      background-color: $white-color;
      padding: 16px 16px 12px 16px !important;
      .card-title-wrapper,
      .item-title-placeholder {
        min-height: 40px;
        .product-name-wrapper {
          .product-name {
            @include truncate(2);
          }
        }
      }
      .item-description-wrapper {
        display: block;
        margin: unset;
        margin-top: 2px;
        box-sizing: border-box;
        height: 63%; //to make sure item description doesn't go out of the card
        position: relative;
        overflow-y: auto;
        padding-bottom: 30px; // to make sure the ending lines of description are visible
        .view-more-desc-link {
          display: inline;
          background-color: $white-color;
        }
        .item-description {
          display: inline;
          margin-top: 6px;
          margin-bottom: 16px;
          margin-inline-end: 0;
          line-height: 1.6em;
          div {
            display: inline;
          }
        }
      }
    }

    .card-footer {
      width: 100%;
      bottom: 0;
      margin-top: unset;
      display: flex;
      position: absolute;

      //  background-color: $white-color;
      box-sizing: border-box;
      align-items: center;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      justify-content: space-between;
      .pricing-details-wrapper {
        //max-width: 25%;
        display: unset;
        min-width: 60px;
        .price-wrapper {
          margin-inline-start: unset;
          .original-item-price {
            font-size: 12px;
          }
        }
        .item-quantity {
          display: block;
          font-size: 11px;
        }
      }
      .show-description-btn {
        display: initial;
        position: absolute;
        left: 50%;
        bottom: 50%;
        transform: translate(-50%, -50%);
      }

      .right-action {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .button-wrapper {
          .out-of-stock-cta {
            width: 92px;
            height: 28px;
            white-space: nowrap;
          }
          .add-to-cart-cta,
          .counter-wrapper {
            height: 32px;
            width: 64px;
            // padding: 0 2.5px;
          }
          .add-to-cart-cta {
            box-shadow: 0px 2px 8px rgba(119, 119, 119, 0.16);
          }
        }
        .cutomize-button {
          font-size: 10px !important;
        }
      }
    }
  }
  .item-card-wrapper:first-of-type {
    padding: 0px;
  }
}
