@import "../../styles/colors.scss";
@import "../../styles/mixins.scss";

.app-carousel {
  position: relative;
  overflow-x: auto;
  .item-list {
    display: flex;
    overflow-x: scroll;
    scroll-behavior: smooth;
    padding: 16px 0px;
    .item-card-wrapper {
      overflow: auto;
      min-width: 300px;
      margin-inline-end: 23px !important;
    }
  }
  .scroll-buttons-wrapper {
    .scroll-button {
      top: 30%;
      width: 50px;
      height: 50px;
      cursor: pointer;
      border-radius: 50%;
      position: absolute;
      @include align-center;
      background-color: $white-color;
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
      .left-arrow {
        transform: rotate(180deg);
      }
    }
    .left-arrow-wrapper {
      left: 10px;
    }
    .right-arrow-wrapper {
      right: 10px;
    }
  }
}

@media only screen and (min-width: 768px) {
  .app-carousel {
    position: relative;
    .item-list {
      padding: 0px 0px 16px;
    }
  }
}
