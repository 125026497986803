@mixin no-margin {
  margin: 0px;
}

@mixin align-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin truncate($line-clamp) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $line-clamp;
  overflow: hidden;
}

@mixin field-icon-wrapper {
  width: 48px;
  height: 48px;
  min-width: 48px;
  @media only screen and (min-width: 768px) {
    width: 50px;
    height: 50px;
    min-width: 50px;
  }
}

@mixin field-icon {
  width: 16px;
  height: 16px;
  @media only screen and (min-width: 768px) {
    width: 18px;
    height: 18px;
  }
}

@mixin checkbox-icon {
  width: 18px;
  height: 18px;
  @media only screen and (min-width: 768px) {
    width: 20px;
    height: 20px;
  }
}

@mixin field-error {
  font-size: 12px;
}

//shimmers, wrappers and containers

@mixin wrapper-shimmer {
  box-sizing: border-box;
  background-color: $alabaster-color;
  width: 100% !important;
  @media only screen and (min-width: 768px) {
    min-height: calc(100vh - 350px);
  }
}

@mixin container-shimmer {
  box-sizing: border-box;
  background-color: $white-color;
  padding: 0 12px;
  @media only screen and (min-width: 768px) {
    padding: 16px 0 0 0;
  }
  @media only screen and (min-width: 1024px) {
    padding: 20px 0 0 0;
  }
  @media only screen and (min-width: 1200px) {
    padding: 24px 0 0 0;
  }
}

@mixin header-shimmer {
  width: 140px;
  height: 12px;
  border-radius: 16px;
  margin-bottom: 18px;
  padding: 4px;
  @media only screen and (min-width: 768px) {
    width: 150px;
    padding: 4px;
  }
  @media only screen and (min-width: 1024px) {
    width: 160px;
    padding: 6px;
  }
  @media only screen and (min-width: 1200px) {
    width: 180px;
    padding: 8px;
  }
}

@mixin description-shimmer {
  height: 4px;
  margin-bottom: 15px;
  border-radius: 16px;
  padding: 2px;
  @media only screen and (min-width: 768px) {
    padding: 4px;
  }
  @media only screen and (min-width: 1024px) {
    padding: 8px;
  }
}

@mixin noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
