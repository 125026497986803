@import "../../../styles/colors.scss";
@import "../../../styles/mixins.scss";

.wallet-wrapper-shimmer {
  @include wrapper-shimmer;
  padding-top: 0 !important;
  .wallet-container-shimmer {
    background-color: $alabaster-color !important;
    @include container-shimmer;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .wallet-section-shimmer {
      padding: 12px;
      background-color: $white-color !important;
    }
    .wallet-section-shimmer:nth-child(1) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      > div {
        .wallet-header-shimmer {
          @include header-shimmer;
        }
        .wallet-icon-placeholder-shimmer {
          height: 100px;
          width: 100%;
          border-radius: 16px;
          margin-bottom: 70px;
        }
        .wallet-placeholder-one-shimmer {
          @include description-shimmer;
          width: 95% !important;
          height: 30px;
          margin-bottom: 32px;
        }
        .wallet-placeholder-amount-shimmer {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 20px;
          margin-bottom: 62px;
          max-width: 300px;
          > div {
            height: 30px;
            flex: 1;
            border-radius: 8px !important;
          }
        }
      }
      .wallet-button-shimmer {
        height: 40px;
        width: 100%;
        border-radius: 16px;
      }
    }
    .wallet-section-shimmer:nth-child(2) {
      .wallet-header-shimmer {
        @include header-shimmer;
      }
      .wallet-section-card-shimmer {
        border-bottom: 1px solid rgba(224, 224, 224, 1);
        padding: 10px;
        margin: 32px 0;
        .wallet-placeholder-one-shimmer {
          height: 14px;
          width: 100%;
          margin-bottom: 24px;
          border-radius: 16px;
        }
        .wallet-placeholder-two-shimmer {
          height: 14px;
          width: 50%;
          border-radius: 16px;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .wallet-wrapper-shimmer {
    .wallet-container-shimmer {
      display: grid;
      grid-gap: 24px;
      box-sizing: border-box;
      grid-template-columns: calc(35% - 24px) 65%;
      .wallet-section-shimmer {
        padding: 24px;
        box-sizing: border-box;
      }
      .wallet-section-shimmer:nth-child(1) {
        padding: 24px;
      }
      .wallet-section-shimmer:nth-child(2) {
        height: calc(100vh - 150px);
        overflow-y: auto;
      }
    }
  }
}
