@import "../../../styles/colors.scss";
@import "../../../styles/mixins.scss";

.checkout-wrapper-shimmer {
  @include wrapper-shimmer;
  .checkout-container-shimmer {
    @include container-shimmer;
    padding: 32px 12px;
    border-radius: 8px;
    background-color: $alabaster-color;
    display: flex;
    flex-direction: column;
    gap: 24px;
    .checkout-left-section-shimmer {
      width: 100%;
      margin: 0;
      display: flex;
      flex-direction: column;
      gap: 24px;
      > div {
        background-color: $white-color;
        padding: 32px;
      }
      .checkout-header-shimmer {
        @include header-shimmer;
      }
      .checkout-widget-shimmer {
        .customer-info-wrapper {
          .item-update-placeholder {
            display: flex;
            margin-top: 24px;
            align-items: center;
            .item-image-placeholder {
              width: 54px;
              height: 54px;
              border-radius: 16px;
            }
            .item-update-placeholder-wrapper {
              margin-inline-start: 20px;
              width: 80%;
              display: flex;
              flex-direction: column;
              .placeholder-1 {
                width: 50%;
                height: 10px;
                margin-bottom: 20px;
                border-radius: 20px;
              }
              .placeholder-2 {
                width: 50%;
                height: 10px;
                border-radius: 20px;
              }
            }
          }
        }
      }
      .checkout-add-more-shimmer {
        height: 10px;
        width: 100%;
        border-radius: 16px;
        margin: 0 !important;
      }
      .checkout-bill-text-one-shimmer {
        height: 30px;
        width: 50%;
        border-radius: 16px;
        margin-top: 25px;
        margin-bottom: 30px;
        display: block;
      }
    }
    .checkout-right-section-shimmer {
      background-color: $white-color;
      padding: 32px;
      height: max-content;
      padding-bottom: 32px;
      .checkout-header-shimmer {
        @include header-shimmer;
      }
      .checkout-bill-text-one-shimmer {
        height: 50px;
        width: 100%;
        border-radius: 16px;
        margin-top: 22px;
        margin-bottom: 30px;
      }
      .checkout-bill-text-two-shimmer {
        height: 10px;
        border-radius: 16px;
        width: 60%;
        margin-bottom: 30px;
      }
      .bill-breakup-wrapper {
        .cart-header-shimmer {
          @include header-shimmer;
          margin: 42px 0 32px;
        }
        .cart-bill-shimmer-wrapper {
          display: flex;
          justify-content: space-between;
          margin-bottom: 20px;
          .cart-bill-text-one-shimmer {
            height: 10px;
            width: 50%;
            border-radius: 16px;
          }
          .cart-bill-text-two-shimmer {
            height: 10px;
            border-radius: 16px;
            width: 20%;
          }
        }
        .cart-bill-shimmer-wrapper:nth-child(4) {
          margin-top: 40px;
          .cart-bill-text-one-shimmer {
            height: 20px;
          }
          .cart-bill-text-two-shimmer {
            height: 20px;
          }
        }
        .cart-bill-text-three-shimmer {
          height: 10px;
          border-radius: 16px;
          width: 60%;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .checkout-wrapper-shimmer {
    .checkout-container-shimmer {
      justify-content: space-between;
      box-sizing: border-box;
      flex-direction: row;
      .checkout-left-section-shimmer {
        flex: 2.1;
        .checkout-widget-shimmer {
          .customer-info-wrapper {
            display: grid;
            grid-template-columns: auto auto;
          }
        }
      }
      .checkout-right-section-shimmer {
        flex: 1;
      }
    }
  }
}
