@import "../../styles/mixins.scss";
@import "../../styles/colors.scss";
@import "../../styles/mixins.scss";

.full-width {
  width: 100% !important;
}

@mixin responsive-button {
  //since we support multiple languages, we can't really keep the button size fixed, it should always fit-content
  min-width: fit-content;
  // height: 34px;
}

.app-button-xyz {
  cursor: pointer;
  border: 1px solid;
  border-radius: 8px;
  @include align-center;
  @include responsive-button;
}

.disabled-button {
  cursor: not-allowed;
}
