.pg-modal-form {
  .pg-dialog-icon {
    display: flex;
    width: 100%;
    justify-content: right;
  }

  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 768px) {
  .pg-modal-form {
    width: 600px;

    .loading {
      min-width: 600px;
      min-height: 300px;
    }
  }
}

@media (max-width: 767px) {
  .pg-modal-form {
    .pg-dialog-icon {
      padding: 20px;
    }

    .loading {
      width: 100%;
      height: 100vh;
    }

    button {
      max-width: 100%;
    }
  }
}
