@import "../../../styles/colors.scss";
@import "../../../styles/mixins.scss";

.page-not-found-wrapper-shimmer {
  @include wrapper-shimmer;
  min-height: calc(100vh - 200px) !important;
  display: grid;
  place-content: center;
  .page-not-found-container-shimmer {
    @include container-shimmer;
    background-color: $alabaster-color !important;
    display: grid;
    place-content: center;
    place-items: center;
    gap: 10px;
    .page-not-found-image-shimmer {
      height: 200px;
      width: 20vh;
      min-width: 350px;
      border-radius: 16px;
      margin-bottom: 24px;
    }
    .page-not-found-placeholder-one-shimmer {
      @include description-shimmer;
      width: 60%;
    }
    .page-not-found-placeholder-two-shimmer {
      @include description-shimmer;
      width: 80%;
    }
    .page-not-found-button-shimmer {
      width: 116px;
      height: 40px;
      margin: 0px auto;
      border-radius: 8px;
    }
  }
}
