@import "../../../styles/colors.scss";
@import "../../../styles/mixins.scss";

.custom-page-wrapper-shimmer {
  @include wrapper-shimmer;
  padding-top: 24px;
  .custom-page-container-shimmer {
    min-height: calc(100vh - 200px) !important;
    @include container-shimmer;
    padding: 12px !important;
    .custom-page-header-shimmer {
      @include header-shimmer;
      margin-bottom: 32px;
    }
    .custom-page-card-shimmer {
      margin-bottom: 50px;
      .custom-page-text-one-shimmer {
        @include description-shimmer;
        width: 95%;
      }
      .custom-page-text-two-shimmer {
        @include description-shimmer;
        width: 80%;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .custom-page-wrapper-shimmer {
    .custom-page-container-shimmer {
      padding: 24px !important;
    }
  }
}
