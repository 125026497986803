@import "../../styles/colors.scss";

.icon-position-ltr {
  position: absolute;
  right: 4px;
  top: 4px;
}
.icon-position-rtl {
  position: absolute;
  left: 4px;
  top: 4px;
}
.favorite-indicator-wrapper {
  padding: 0 5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white-color;
  z-index: 100;
  .icon-wrapper {
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    .likes-count {
      margin-inline-start: 2px;
      margin-inline-end: 2px;
    }
    .favorite-icon {
      width: 20px;
    }
  }
}

@media only screen and (min-width: 768px) {
  .icon-position-ltr {
    top: 8px;
    right: 8px;
  }
  .icon-position-rtl {
    top: 8px;
    left: 8px;
  }
  .favorite-indicator-wrapper {
    .icon-wrapper {
      .favorite-icon {
        cursor: pointer;
      }
    }
  }
}
