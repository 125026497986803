@import "../../../styles/mixins.scss";
@import "../../../styles/colors.scss";

.choose-address-wrapper {
  overflow: hidden;
  background-color: $white-color;
  height: 100%;
  display: flex;
  flex-direction: column;
  .choose-address-heading {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 4px solid $grey-shade-two;
    margin-bottom: 8px;
    .choose-address-heading-right {
      .close-address-drawer-icon {
        @include align-center;
        cursor: pointer;
      }
    }
  }
  .choose-address-content {
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    .saved-address {
      cursor: pointer;
      border-bottom: 0.1px solid $santas-gray;
      padding: 16px;
      .icon-heading-wrapper {
        display: flex;
        align-items: center;
        .saved-address-tag {
          text-transform: uppercase;
          padding: 0 8px;
          width: fit-content;
        }
      }
      .addressline-wrapper {
        padding: 6px 0;
      }
    }
    .shimmer-wrapper {
      margin: 40px;
      .shimmer-address-one {
        height: 12px;
        width: 80%;
        margin: 15px 0;
        border-radius: 8px;
      }
      .shimmer-address-two {
        height: 12px;
        width: 60%;
        border-radius: 8px;
      }
    }
  }
  .choose-address-footer {
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .save-button {
      margin: 8px;
      height: 56px;
      width: 90%;
    }
  }
}

@media only screen and (min-width: 768px) {
  .choose-address-wrapper {
    .choose-address-heading {
      padding: 24px;
    }
    .choose-address-content {
      .saved-address {
        padding: 16px 32px;
      }
    }
    .choose-address-footer {
      bottom: 0;
      width: 100%;
      display: flex;
      align-self: flex-end;
      .save-button {
        margin: 16px;
      }
    }
  }
}
