@import "../../styles/mixins.scss";
@import "../../styles/colors.scss";

.server-error-wrapper {
  min-height: 36px;
  padding: 0px 10px;
  border-radius: 6px;
  text-align: center;
  margin-bottom: 16px;
  white-space: normal;
  @include align-center;
  border: 1px solid $cinnabar-color;
  background-color: rgba(255, 0, 0, 0.1);
  .error-message {
    color: $cinnabar-color;
  }
}
