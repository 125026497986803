@import "../../../styles/colors.scss";
@import "../../../styles/mixins.scss";

.testimonial-section-wrapper {
  width: 100%;
  height: 460px;
  padding: 24px;
  box-sizing: border-box;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  @media only screen and (min-width: 1024px) {
    height: 480px;
  }
  .testimonial-container {
    .testimonial {
      display: none;
      @media only screen and (min-width: 768px) {
        display: flex;
        align-items: center;
      }
      .author-image-wrapper {
        width: 160px;
        height: 160px;
        overflow: hidden;
        @media only screen and (min-width: 768px) {
          width: 200px;
          height: 200px;
          margin-inline-start: 24px;
          margin-inline-end: 24px;
        }
        .testimonial-author-image {
          width: 100%;
          height: 100%;
          object-position: center;
          object-fit: cover;
        }
      }
      .testimonial-content {
        margin-top: 42px;
        @media only screen and (min-width: 768px) {
          margin-inline-start: 42px;
        }
        .quote-wrapper {
          position: relative;
          .quote {
            position: absolute;
            left: -16px;
            top: -15px;
            font-size: 70px;
            line-height: 0.6em;
            opacity: 0.3;
            color: lightgray;
          }
        }
        .author-name {
          margin-top: 24px;
          letter-spacing: 2px;
          text-transform: uppercase;
        }
        .author-post {
          letter-spacing: 2px;
          text-transform: uppercase;
        }
      }
    }
    /* Next & previous buttons */
    .arrow-wrapper {
      .prev-arrow,
      .next-arrow {
        cursor: pointer;
        position: absolute;
        top: 52%;
        width: 40px;
        height: 40px;
        box-sizing: border-box;
        margin-top: -22px;
        color: white;
        font-weight: bold;
        font-size: 18px;
        transition: 0.6s ease;
        border-radius: 0 3px 3px 0;
        user-select: none;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .next-arrow {
        right: 0;
        border-radius: 3px 0 0 3px;
      }
      .prev-arrow {
        left: 0;
        border-radius: 3px 0 0 3px;
      }
      .prev-arrow:hover,
      .next-arrow:hover {
        background-color: rgba(0, 0, 0, 0.8);
      }
    }
  }
  .carousel-indicator {
    position: absolute;
    left: 50%;
    bottom: 10px;
    transform: translateX(-50%);
    .dot {
      cursor: pointer;
      height: 8px;
      width: 8px;
      margin: 0 5px;
      border-radius: 50%;
      display: inline-block;
      transition: background-color 0.6s ease;
    }
  }
}
