@import "../../../styles/colors.scss";
@import "../../../styles/mixins.scss";

.refer-and-earn-wrapper-shimmer {
  @include wrapper-shimmer;
  padding-top: 24px;
  .refer-and-earn-container-shimmer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 14px;
    @include container-shimmer;
    .refer-and-earn-header-shimmer {
      @include header-shimmer;
      margin-top: 32px;
    }
    .refer-and-earn-image-shimmer {
      height: 20vh;
      min-width: 200px;
      width: 30%;
      border-radius: 16px;
      margin-bottom: 32px;
    }
    .refer-and-earn-placeholder-one-shimmer {
      @include description-shimmer;
      width: 60% !important;
    }
    .refer-and-earn-placeholder-two-shimmer {
      height: 30px;
      border-radius: 16px;
      width: 50% !important;
    }
    .share-links-shimmers {
      display: grid;
      grid-template-columns: auto;
      width: 40%;
      grid-gap: 24px;
      padding-bottom: 40px;
      .refer-and-earn-placeholder-three-shimmer {
        height: 30px;
        width: 100% !important;
        border-radius: 16px;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .refer-and-earn-wrapper-shimmer {
    @include wrapper-shimmer;
    .refer-and-earn-container-shimmer {
      gap: 24px;
      .refer-and-earn-image-shimmer {
        height: 30vh;
      }
      .share-links-shimmers {
        grid-template-columns: auto auto;
      }
    }
  }
}
