.generic-error-dialog-wrapper {
  width: 100%;
  text-align: center;
  .generic-error-dialog-header {
    display: flex;
    justify-content: flex-end;
    .close-dialog-icon {
      cursor: pointer;
    }
  }
  .generic-error-dialog-title {
    margin-top: 24px;
  }
  .generic-error-dialog-desc {
    margin-top: 12px;
  }
  .generic-error-dialog-refresh {
    margin-top: 24px;
    padding: 5px 0;
  }
}
